import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Typography,
} from 'antd';
import React from 'react';
import styled from 'styled-components';
import NiceModal from '@ebay/nice-modal-react';
import moment from 'moment';

import {
  AddressInterface,
  safeguardingLevelChecks,
  safeguardingRoles,
} from '../types/SafeguardingTypes';
import { CdUserAvatar } from '../../user/components/cd-user-avatar/CdUserAvatar';
import { gettextCatalog } from '../../services/I18nService';
import { useSafeguardingForm } from '../hooks/useSafeguardingForm';
import { CdParishSelect } from '../../shared/components/cd-perish-select/CdParishSelect';

import { FileDisplayTable } from './FileDisplayTable';
import { FileDisplayTableColumns } from './FileDisplayTableColumn';
import { showUserAddressModal } from './UserAddressModal';

import { showConfirmModal } from '@/react/shared/components/cd-confirm-modal/CdConfirmModal';
import {
  getSafeguardingLevelText,
  safeguardingFormSelect,
} from '@/react/safeguarding/common/Helpers';
import {
  CdCircleCheck,
  CdEditIcon,
  CdPlus,
} from '@/react/shared/components/Icons';
import { organizationStatus } from '@/react/user/screens/UserList';
import { CdVerticalSpace } from '@/react/shared/components/cd-vertical-space/CdVerticalSpace';

const StyledCard = styled(Card)`
  &&&&.ant-card-bordered {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const SafeguardingForm = ({
  isEdit,
  currentUserId,
  currentUserOrganizationStatus,
}: {
  isEdit?: boolean;
  currentUserId: number;
  currentUserOrganizationStatus: string;
}) => {
  const {
    safeguardingForm,
    safeguardingData,
    onSafeguardingInfoSaveHandler,
    deleteFile,
    isMultiChurch,
    getAddressFormat,
  } = useSafeguardingForm(currentUserId);

  const fileDisplayTableRowDeleteHandler = (id: number) =>
    showConfirmModal({
      title: gettextCatalog.getString(
        'Are you sure you want to delete this record?'
      ),
      message: (
        <Typography.Paragraph>
          {gettextCatalog.getString('The record will be deleted permanently.')}
        </Typography.Paragraph>
      ),
      onOk: () => deleteFile(id),
    });

  const onAddressChange = (address: AddressInterface) => {
    safeguardingForm.setFieldsValue({
      address: address,
    });
    return onSafeguardingInfoSaveHandler();
  };

  const showAddress = (address: AddressInterface) => {
    if (address) {
      return (
        <CdVerticalSpace>
          {getAddressFormat()}
          {currentUserOrganizationStatus ===
            organizationStatus.CREATED_WITHOUT_LOGIN && (
            <Button
              style={{ paddingLeft: '0px' }}
              type="link"
              icon={<CdEditIcon />}
              disabled={!isEdit}
              onClick={() =>
                showUserAddressModal({
                  userFullName:
                    safeguardingData.fullName || safeguardingData.email,
                  address: address,
                  onAddressSaveHandler: (value) => onAddressChange(value),
                })
              }
            >
              {gettextCatalog.getString('Edit user address')}
            </Button>
          )}
        </CdVerticalSpace>
      );
    } else {
      return (
        <Button
          style={{ paddingLeft: '0px' }}
          type="link"
          icon={<CdPlus />}
          disabled={!isEdit}
          onClick={() =>
            showUserAddressModal({
              userFullName: safeguardingData.fullName || safeguardingData.email,
              address: address,
              onAddressSaveHandler: (value) => onAddressChange(value),
            })
          }
        >
          {gettextCatalog.getString('Add user address')}
        </Button>
      );
    }
  };

  return (
    <Form
      form={safeguardingForm}
      initialValues={safeguardingData}
      layout="vertical"
    >
      <Row style={{ marginBottom: '24px', marginTop: '24px' }}>
        <StyledCard>
          <Col span={24}>
            <Space direction="horizontal">
              <CdUserAvatar picture={safeguardingData?.pictureUri} size={80} />
              <CdVerticalSpace style={{ marginLeft: '24px' }} size={2}>
                <Typography.Title level={2}>
                  {safeguardingData?.fullName}
                </Typography.Title>
                <Typography.Text>{safeguardingData?.email}</Typography.Text>
                <Typography.Text>
                  {safeguardingData?.birthdayDate &&
                    moment(safeguardingData?.birthdayDate).format('LL')}
                </Typography.Text>

                <Form.Item noStyle name="address">
                  {showAddress(safeguardingData?.address)}
                </Form.Item>
                {safeguardingData?.highestValidatedLevelInChurchDesk && (
                  <Space direction="horizontal">
                    <Typography.Text>
                      {gettextCatalog.getString(
                        'Verified by third party: {{level}}',
                        {
                          level: getSafeguardingLevelText(
                            safeguardingData?.highestValidatedLevelInChurchDesk
                          ),
                        }
                      )}
                    </Typography.Text>
                    <CdCircleCheck type="success" />
                  </Space>
                )}
              </CdVerticalSpace>
            </Space>
          </Col>
        </StyledCard>
        <StyledCard>
          <Col span={24}>
            <Typography.Title level={2} style={{ margin: '0 0 24px' }}>
              {gettextCatalog.getString('Safeguarding information')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item
              name={['safeguardInfo', 'type']}
              label={gettextCatalog.getString('Work')}
              extra={gettextCatalog.getString('Select the type of employment')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Select
                disabled={!isEdit}
                options={safeguardingRoles().map((role) => ({
                  label: role.label,
                  value: role.value,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={['safeguardInfo', 'workAndResponsibilities']}
              label={gettextCatalog.getString('Work field & responsibilities')}
              extra={gettextCatalog.getString(
                'Describe the work and responsibilities'
              )}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Input disabled={!isEdit} />
            </Form.Item>
          </Col>
          {isMultiChurch && (
            <Col span={24}>
              <Form.Item
                name={['safeguardInfo', 'parishIds']}
                label={gettextCatalog.getString('Parish')}
                extra={gettextCatalog.getString('Choose one or more parishes')}
                rules={[
                  {
                    required: true,
                    message: gettextCatalog.getString(
                      'This field is required.'
                    ),
                  },
                ]}
              >
                <CdParishSelect disabled={!isEdit} />
              </Form.Item>
            </Col>
          )}

          <Col span={24}>
            <Form.Item
              name={['safeguardInfo', 'safeguardRequirement']}
              label={gettextCatalog.getString('Safeguarding measures required')}
              rules={[
                {
                  required: true,
                  message: gettextCatalog.getString('This field is required.'),
                },
              ]}
            >
              <Select
                disabled={!isEdit}
                options={safeguardingLevelChecks().map(safeguardingFormSelect)}
              />
            </Form.Item>
          </Col>
          {isEdit && (
            <Col
              span={24}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Space size="small">
                <Button
                  type="default"
                  onClick={() => NiceModal.hide('SafeguardingDrawer')}
                >
                  {gettextCatalog.getString('Close')}
                </Button>
                <Button type="primary" onClick={onSafeguardingInfoSaveHandler}>
                  {gettextCatalog.getString('Save')}
                </Button>
              </Space>
            </Col>
          )}
        </StyledCard>
        {/* Documents card */}
        <StyledCard>
          <Col span={24}>
            <Typography.Title level={2} style={{ margin: '0 0 24px' }}>
              {gettextCatalog.getString('Documents')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item name="documents">
              <FileDisplayTable
                currentUserId={currentUserId}
                fileType="document"
                tableColumns={FileDisplayTableColumns(
                  'document',
                  isEdit,
                  fileDisplayTableRowDeleteHandler
                )}
                isEdit={isEdit}
              />
            </Form.Item>
          </Col>
        </StyledCard>
        {/* Courses card */}
        <StyledCard>
          <Col span={24}>
            <Typography.Title level={2} style={{ margin: '0 0 24px' }}>
              {gettextCatalog.getString('Courses')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item name="courses">
              <FileDisplayTable
                currentUserId={currentUserId}
                fileType="course"
                tableColumns={FileDisplayTableColumns(
                  'course',
                  isEdit,
                  fileDisplayTableRowDeleteHandler
                )}
                isEdit={isEdit}
              />
            </Form.Item>
          </Col>
        </StyledCard>
        {/* Certificate of conduct card */}
        <StyledCard>
          <Col span={24}>
            <Typography.Title level={2}>
              {gettextCatalog.getString('Certificate of Conduct')}
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.Item name="certificatesOfConduct">
              <FileDisplayTable
                currentUserId={currentUserId}
                fileType="certificateOfConduct"
                tableColumns={FileDisplayTableColumns(
                  'certificateOfConduct',
                  isEdit,
                  fileDisplayTableRowDeleteHandler
                )}
                isEdit={isEdit}
              />
            </Form.Item>
          </Col>
        </StyledCard>
      </Row>
    </Form>
  );
};
